export default [
    {
        key: "name",
        label: "Name",
        //thStyle: { width: "20%" },
        //thClass: "text-center",
        //tdClass: "text-center",
        //class: "text-left",
        sortable: false,
        visible: true,
    },
    // {
    //     key: "percentage",
    //     label: "Percentage",
    //     //thStyle: { width: "50%" },
    //     //thClass: "text-center",
    //     //tdClass: "text-center",
    //     //class: "text-left",
    //     sortable: false,
    //     visible: true,
    // },
    // {
    //     key: "status",
    //     label: "Efectivity",
    //     thStyle: { width: "30%" },
    //     //thClass: "text-center",
    //     //tdClass: "text-center",
    //     //class: "text-left",
    //     sortable: false,
    //     visible: true,
    // },
    {
        key: "created_at",
        label: "CREATION DATE",
        //thStyle: { width: "30%" },
        //thClass: "text-center",
        //tdClass: "text-center",
        //class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "actions",
        label: "ACTIONS",
        //thStyle: { width: "30%" },
        //thClass: "text-center",
        tdClass: "text-left",
        //class: "text-left",
        sortable: false,
        visible: true,
    },
]