<template>
  <b-nav
    card-header
    pills
    class="m-1 ml-1"
  >
    <b-nav-item
      :to="{ name: 'connection-loyal-clients-with-results-old' }"
      exact
      exact-active-class="active"
      :link-classes="['px-3', bgTabsNavs]"
    >
      WITH RESULTS
      <span
          class="
            position-absolute
            small
            top-0
            start-100
            translate-middle
            badge
            rounded-pill
            bg-danger
          "
          style="font-size: 90% !important; margin-left: 120px; margin-top: -2px"
        >
          <feather-icon
            icon
            :badge="
              G_COUNTER_LOYAL_WITHOUT_READING_RESULT > 99 ? '99+' : G_COUNTER_LOYAL_WITHOUT_READING_RESULT
            "
            badge-classes="badge-danger badge-glow "
          />
        </span>
    </b-nav-item>
    <b-nav-item
      :to="{ name: 'connection-loyal-clients-no-results-old' }"
      exact
      exact-active-class="active"
      :link-classes="['px-3', bgTabsNavs]"
    >
      NO RESULTS
      <span
          class="
            position-absolute
            small
            top-0
            start-100
            translate-middle
            badge
            rounded-pill
            bg-danger
          "
          style="font-size: 90% !important; margin-left: 105px; margin-top: -2px"
        >
          <feather-icon
            icon
            :badge="
              G_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT > 99 ? '99+' : G_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT
            "
            badge-classes="badge-danger badge-glow "
          />
        </span>
    </b-nav-item>
  </b-nav>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      activeBtn: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_COUNTER_LOYAL_WITHOUT_READING_RESULT: "connectionStore/G_COUNTER_LOYAL_WITHOUT_READING_RESULT",
      G_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT: "connectionStore/G_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT", 
    }),
    ...mapState("connectionStore", ["S_COUNTER_RECYCLE"]),

  },
  async created() {
    this.isActiveTab();
  },
  methods: {
    isActiveTab() {
      this.$route.meta.parent_tab == "connection-recycle-clients"
        ? (this.activeBtn = "bg-primary")
        : (this.activeBtn = "");
    },
  },
};
</script>