<template>
  <b-modal
    v-model="modalState"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    title="AGENTS"
    @hidden="close()"
  >
    <b-form-group>
      <b-input-group prepend="Clients">
        <div
          class="form-control h-auto bg-transparent border-secondary sms-container"
        >
          <template>
            <span v-for="(client, index) in localListClients" :key="index">
              <span>{{ client.name }}</span>
              <feather-icon
                v-if="localListClients.length > 1"
                class="text-danger cursor-pointer"
                icon="MinusCircleIcon"
                @click="removeClient(client.client_account_id)"
              />&nbsp;
            </span>
          </template>
        </div>
      </b-input-group>
    </b-form-group>
    <b-row>
      <b-col>
        <b-table
          small
          show-empty
          :fields="fields"
          :items="listAdvisors"
          class="font-small-3 text-center"
          sticky-header="50vh"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(radio)="data">
            <div class="text-center ml-1">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="selectAgent"
                  name="some-radios"
                  :value="data.item.agent_id"
                  :class="ariaDescribedby"
                ></b-form-radio>
              </b-form-group>
            </div>
          </template>

          <template #cell(user_name)="data">
            {{ data.item.agent_name }}
          </template>

          <template #cell(loyal)="data">
            <div class="d-flex justify-content-center">
              <div
                class="class_loyal_hold"
                :style="countLetters(data.item.count_clients_loyal)"
              >
                {{ data.item.count_clients_loyal }}
              </div>
            </div>
          </template>

          <template #cell(hold)="data">
            <div class="d-flex justify-content-center">
              <div
                class="class_loyal_hold"
                :style="countLetters(data.item.count_clients_loyal)"
              >
                {{ data.item.count_clients_hold }}
              </div>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-center mt-1">
          <span class="text-danger">
            <small>
              If you do not find the agent you need, please inform your chief.
            </small>
          </span>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        @click="sendAgentClients()"
        :disabled="selectAgent ? false : true"
      >
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ServicesConnection from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service";
import Fields from "@/views/ce-digital/sub-modules/connection/views/clients/data/fields.asignadvisormodalgrid.fields.data.js";

export default {
  props: {
    listClients: {
      type: Array,
    },
    listAdvisors: {
      type: Array,
      default: [],
    },
    typeOfAssignment: {
      type: Number,
      default: 1, // (1) Assign (2) Reassign
    },
  },
  components: {},
  data() {
    return {
      modalState: true,
      localListClients: this.listClients,
      fields: Fields,
      isBusy: false,
      selectAgent: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    selectedAdministrator() {
      // Find the administrator related to the agent selected
      return this.listAdvisors[
        this.listAdvisors.findIndex(
          (usersGroup) => usersGroup.agent_id === this.selectAgent
        )
      ].admin_id;
    },
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async sendAgentClients() {
      if (this.selectAgent) {
        const nameAgent =
          this.listAdvisors[
            this.listAdvisors.findIndex(
              (usersGroup) => usersGroup.agent_id === this.selectAgent
            )
          ].agent_name;
        this.$swal({
          title: "Confirm",
          icon: "warning",
          text: `${this.localListClients.length} ${this.$route.meta.breadcrumb[0].text} + clients are being sent to Agent`, // this.localListClients.length + ' ' + this.$route.meta.breadcrumb[0].text + " clients are being sent to Agent",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.addPreloader();
            let params = {
              clientsList: this.localListClients,
              advisor_id: this.selectAgent,
              user_id: this.currentUser.user_id,
            };
            const { data } = await ServicesConnection.sendClientsAgentMultiple(
              params
            );
            if (data.msg) {
              this.localListClients = [];
              this.removePreloader();
              this.showSuccessSwal(data.msg, "Sucess!");
              this.refreshTableParent();
              this.$parent.getAllAdvisors();
              this.close();
              this.$emit("refresh", nameAgent);
            }
          }
        });
      }
    },
    close() {
      this.$emit("close");
    },
    refreshTableParent() {
      // this.$parent.$refs["refClientsList"].refresh(); //table parent
    },
    font(size) {
      return { fontSize: size + "px" };
    },
    countLetters(texT_data) {
      return (texT_data + "").length == 1
        ? this.font(12)
        : (texT_data + "").length == 2
        ? this.font(11)
        : (texT_data + "").length == 3
        ? this.font(10)
        : this.font(9);
    },
    removeClient(clientAccountId) {
      this.localListClients = this.localListClients.filter(
        (client) => client.client_account_id !== clientAccountId
      );
    },
  },
};
</script>
<style>
.padding-y {
  padding: 5px 0 !important;
}
.class_loyal_hold {
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0065a1;
  border-radius: 15px;
  font-size: 11px;
}
</style>
