export const Fields = [
    {
        key: "selected",
        label: "xx",
        visible: true,
    },
    {
        key: "lead_name",
        label: "Client Name",
        class: "text-left ",
        visible: true,
    },
    {
        key: "efectivity_percentage",
        label: "Effectivity",
        class: "text-center",
        visible: true,
        sortable: true,
        orderByField: 1,
    },
    {
        key: "advisor_connection_name",
        label: "Agent",
        class: "text-center",
        visible: true,
        sortable: true,
        orderByField: 2,
    },
    {
        key: "balance",
        label: "Balance",
        class: "text-center",
        visible: false,
        sortable: true,
        orderByField: 3,
    },
    {
        key: "last_payment",
        label: "Last Payment",
        class: "text-center",
        sortable: true,
        visible: true,
        orderByField: 4,
    },
    {
        key: "send_to_connection_at",
        label: "Start Connection",
        class: "text-center",
        visible: true,
        sortable: true,
        orderByField: 5,
    },
    {
        key: "enroll_date",
        label: "Enroll Date",
        class: "text-center",
        visible: true,
        sortable: true,
        orderByField: 7,
    },
    {
        key: "st",
        label: "ST",
        class: "text-center",
        visible: true,
        sortable: true,
        orderByField: 6,
    },
    {
        key: "services",
        label: "Actions",
        class: "text-center",
        visible: true,
    }
];

export const getSortBy = (key) => {
    let exists = Fields.find(x => x.key === key);
    if (exists) {
        return exists.orderByField;
    } else {
        return 2;
    }
}