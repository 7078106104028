<template>
  <div>
    <ClientsTabs />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="refreshTableClients()"
      @reset-all-filters="updateTable"
      class="filter-slot-new-customization"
      :top-paginate="false"
    >
      <template #buttons>
        <b-button
          class="ml-1 position-relative"
          v-if="stateAssign"
          variant="outline-primary"
          v-b-tooltip.hover.right="'Assign client'"
          :disabled="!existClientsWithOutAdvisorAssigned"
          @click="openModalAsign()"
        >
          ASSIGN
          <feather-icon icon="SendIcon" class="mr-50 ml-1" size="15" />
        </b-button>
      </template>

      <template #table>
        <b-table
          slot="table"
          ref="refClientsCeTable"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="blue-scrollbar position-relative table-new-customization"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>
          <template #cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clientsSelected"
                :value="data.item"
                :name="data.item.account_id"
                class="mt-1"
                :disabled="false"
                :checked="false"
              />
            </b-form-group>
          </template>
          <template #cell(lead_name)="data">
            <div class="d-flex gap-5">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'connection-clients-account',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
              >
                {{ data.item.lead_name }}
              </router-link>
              <b-badge v-if="data.item.sales" variant="light-info">
                IN SALES
              </b-badge>
            </div>
            <br v-if="data.item.user_responsible" />
            <div v-if="data.item.user_responsible" class="text-warning">
              <tabler-icon
                :icon="
                  data.item.user_responsible === currentUser.user_id
                    ? 'StarIcon'
                    : 'LockIcon'
                "
                size="15"
              />
              <strong>CEO's client</strong>
            </div>
            <status-account :account="data.item" :text="true"></status-account>

            <div class="d-flex" style="gap: 5px">
              <span>{{ data.item.account }}</span>
              <b-badge v-if="data.item.claims != 0" variant="light-info">
                IN CLAIM ({{ data.item.claims }})
              </b-badge>
            </div>

            <span v-if="data.item.client_status_request == 1">
              <feather-icon
                v-b-tooltip.hover.top="'Recycle Pending'"
                class="cursor-pointer ml-1"
                icon="TrashIcon"
                size="17"
                style="color: #0f0"
              />
            </span>
            <template v-if="data.item.client_status_request == 4">
              <span>
                <feather-icon
                  v-b-tooltip.hover.top="'Closed Pending'"
                  class="cursor-pointer ml-1"
                  icon="TrashIcon"
                  size="17"
                  style="color: #ff0000"
                />
              </span>
              <br />
            </template>
            <span v-if="data.item.lead_mobile"
              ># {{ data.item.lead_mobile }}</span
            >
          </template>
          <template #cell(efectivity_percentage)="data">
            <p
              v-if="data.item.efectivity_percentage >= 40"
              class="text-success font-weight-bold"
            >
              {{ data.item.efectivity_percentage }} %
            </p>
            <p v-else class="text-danger font-weight-bold">
              {{ data.item.efectivity_percentage }} %
            </p>
          </template>
          <template #cell(advisor_connection_name)="data">
            <div style="position: relative">
              <span
                style="margin-left: 5px"
                :class="
                  !!data.item.advisor_connection_name ? '' : 'text-danger'
                "
              >
                {{
                  !!data.item.advisor_connection_name
                    ? data.item.advisor_connection_name
                    : "Not assigned"
                }}
              </span>
            </div>
          </template>
          <template #cell(balance)="data">
            <span> $ {{ data.item.balance | currencyZero }} </span>
          </template>
          <template #cell(last_payment)="data">
            <LastPaymentItem :client-account-id="data.item.account_id" />

            <div class="mp-pending">
              <feather-icon
                icon="CreditCardIcon"
                v-if="data.item.pending_payments == 1"
                class="ml-1"
                style="margin-top: 5px"
                size="18"
                v-b-tooltip.hover
                title="1 Monthly Payment Pending"
              ></feather-icon>
            </div>
          </template>
          <template #cell(send_to_connection_at)="data">
            {{ data.item.send_to_connection_at | myGlobal }}
          </template>
          <template #cell(enroll_date)="data">
            {{ data.item.enroll_date | myGlobal }}
          </template>
          <template #cell(st)="data">
            <span>
              {{ data.item.st }}
            </span>
          </template>
          <template #cell(services)="data">
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
              :toggle-class="toogleClass"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                variant="warning"
                @click="openAddService(3, data.item)"
              >
                <feather-icon icon="FilePlusIcon" />
                <span class="align-middle ml-50 text-uppercase"
                  >Add service</span
                >
              </b-dropdown-item>
              <!-- Change Service -->
              <b-dropdown-item
                :variant="'success'"
                @click="openAddService(4, data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">CHANGE SERVICE</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!isAgent"
                variant="primary"
                @click="openModalAsignAgentIndividual(data.item)"
              >
                <feather-icon icon="UserIcon" />
                <span class="align-middle ml-50">ASSIGN AGENT</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <change-client-status-modal
      v-if="isChangeClientStatusModalOpened"
      :type-status-request="typeStatusRequest"
      :current-client="currentClient"
      @closeAndUpdate="closeSendToRecycleModalAfterAction"
      @close="closeSendToRecycle"
      @refresh="refreshTableClients"
    />
    <set-services-modal
      v-if="isSetServicesModalOpened"
      :service-action-type="serviceActionType"
      :current-client="currentClient"
      :headerS="headerS"
      @closeAndUpdate="closeSetServicesModalAfterAction"
      @close="closeSetServicesModal"
      ref="set_services_modal"
    />

    <monthly-payment-modal
      v-if="isMonthlyPaymentModalOpened"
      @close="closeMonthlyPaymentModal"
      @paymentSaveSuccessfully="
        connectionProcessWhenHoldPayMonthlyPayment($event)
      "
    />

    <assign-advisor-modal-grid
      v-if="modalAssignAdvisorState"
      @close="closeModalAssignAdvisor"
      :listClients="clientsToAssignAdvisor"
      :listAdvisors="advisorsList"
      :type-of-assignment="typeOfAssignment"
      @refresh="refreshTableClientsAssign"
    />
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="name_clients_arr[0]"
      :typesms="0"
      @closeModal="closeSendSms"
      @addSms="addCountSms"
      @refresh="$refs.refClientsCeTable.refresh()"
    />

    <new-relationship-modal
      v-if="activeNewRelationshipModal"
      :active="activeNewRelationshipModal"
      :pre-selected-agent-id="preSelectedAgentId"
      @close="closeNewRelationshipModal($event)"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <!-- derivation action -->
    <derivation-action-modal
      v-if="derivationActionModal"
      :derivation-data="derivationData"
      @send="updateRow"
      @closeModal="closeDerivationActionModal"
    />
    <!-- derivation tracking modal-->
    <derivation-tracking-modal
      v-if="derivationTrackingModal"
      :derivation-data="derivationData"
      @closeModal="closeDerivationTrackingModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  Fields,
  getSortBy,
} from "@/views/ce-digital/sub-modules/connection/views/clients/data/fields.clients.datav2.js";
import Filters from "@/views/ce-digital/sub-modules/connection/views/clients/data/filters.clients.data";
import ClientsConnectionService from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
import ChangeClientStatusModal from "@/views/ce-digital/sub-modules/connection/views/clients/components/modals/ChangeClientStatusModal.vue";
import SetServicesModal from "@/views/ce-digital/sub-modules/connection/views/clients/components/modals/SetServicesModal.vue";
import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import AssignAdvisorModalGrid from "@/views/ce-digital/sub-modules/connection/views/clients/components/modals/AsignAdvisorModalGrid.vue";
import DashboardService from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/service/dashborard.service.js";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import LoyalResultsTabs from "@/views/ce-digital/sub-modules/connection/views/clients/components/LoyalResultsTabs.vue";
import RecycleTabs from "@/views/ce-digital/sub-modules/connection/views/clients/components/RecycleTabs.vue";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import NewRelationshipModal from "@/views/ce-digital/sub-modules/settings/views/users-relationship/components/NewRelationshipModal.vue";
import ModalAddService from "@/views/commons/components/digital/modals/ModalAddService.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import { BFormInput, BButton } from "bootstrap-vue";
import GlobalService from "@/service/global/index";
import ClientLabelSelect from "@/views/commons/components/reports/tabs/debtor-clients/components/ClientLabelSelect.vue";
import ResultSelect from "@/views/commons/components/reports/tabs/debtor-clients/components/ResultSelect.vue";
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
import ClientsTabs from "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsTabs.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import LastPaymentItem from "@/views/ce-digital/components/LastPaymentItem.vue";
import Vue from "vue";
export default {
  components: {
    ClientsTabs,
    ActionsTable,
    ChangeClientStatusModal,
    SetServicesModal,
    MonthlyPaymentModal,
    AssignAdvisorModalGrid,
    BFormInput,
    BButton,
    ModalSendSms,
    LoyalResultsTabs,
    RecycleTabs,
    NewRelationshipModal,
    ModalAddService,
    DerivationActionModal,
    DerivationTrackingModal,
    StatusAccount,
    IconStatus,
    ClientLabelSelect,
    ResultSelect,
    StatusAccount,
    LastPaymentItem,
  },
  emits: ["add"],
  data() {
    return {
      toogleClass: {
        type: String,
        default: "",
      },
      activeNewRelationshipModal: false,
      clientsWithOutAdvisor: 0,
      serviceActionType: null,
      advisorsList: [],
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      clients: [],
      clientsSelected: [],
      clientsToAssignAdvisor: [],
      clientsToAssignAdvisorSelected: [],
      selectAll: false,
      currentClient: null,
      endPage: "",
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      isBusy: false,
      isMonthlyPaymentModalOpened: false,
      isChangeClientStatusModalOpened: false,
      isSetServicesModalOpened: false,
      modalSendSms: false,
      derivationActionModal: false,
      derivationTrackingModal: null,
      derivationData: null,
      modalOpenAddService: false,
      nextPage: "",
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      preSelectedAgentId: null,
      toPage: null,
      totalRows: 0,
      sortBy: "",
      sortDesc: false,
      startPage: null,
      headerS: {
        program: 3,
        seller: "",
        captured: "",
      },
      modalAssignAdvisorState: false,
      stateAssign: false,
      typeOfAssignment: null, // (1) Assign (2) Reassign
      counter_sms: 0,
      client_id: "",
      results: [],
      labels: [],
      other: null,
      typeAddChange: null,
    };
  },

  async created() {
    this.fields[4].visible = this.isOutstanding;
    await Promise.all([
      this.getAllAdvisors(),
      this.getAgentsInARelationship(),
      this.getStatesEeuu(),
    ]);
  },
  mounted() {
    this.getResults();
    this.getLabels();
  },
  methods: {
    ...mapActions({
      SET_PROGRAM: "crm-store/SET_PROGRAM",
      SET_CATCHER: "crm-store/SET_CATCHER",
      SET_SELLER: "crm-store/SET_SELLER",
      A_GET_CLIENTS: "DebtSolutionClients/A_GET_CLIENTS",
    }),
    async myProvider(ctx) {
      try {
        let params = {
          status: this.currentTabStatusId,
          lead_name: this.filterPrincipal.model,
          client_connection_state: this.connectionState,
          per_page: this.paginate.perPage,
          module_id: this.currentModule,
          user_role: this.allAccessGrid ? 1 : this.currentRoleId,
          user_id: this.allAccessGrid ? 1 : this.currentUserId,
          page: this.paginate.currentPage,
          advisor_code: this.filters[0].model,
          creation_date_from: this.filters[1].model
            ? this.filters[1].model
            : "",
          creation_date_to: this.filters[2].model ? this.filters[2].model : "",
          date_from: this.filters[3].model ? this.filters[3].model : "", // Connection from date
          date_to: this.filters[4].model ? this.filters[4].model : "", // Connection to date
          client_type: this.filters[5].model, // (1) Regular (2) Digital
          state_eeuu: this.filters[6].model,
          efectivity: {
            from: this.filters[7].model,
            to: this.filters[8].model,
          },
          order_by: getSortBy(ctx.sortBy),
          order: ctx.sortDesc ? "DESC" : "ASC",
        };
        const { data } =
          await ClientsConnectionService.getConnectionClientsLoyalv2(params);
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        this.items = data.data;
        this.clientsWithOutAdvisor =
          data.data[0]?.count_clients_no_advisor_assigned ?? 0;
        return data.data;
      } catch (err) {
        console.error(err);
      }
    },
    async function() {
      this.filterPrincipal.model = "";
      this.$refs.refClientsCeTable.refresh();
    },
    async getAllAdvisors() {
      const { data } = await ClientsConnectionService.getUserModule();
      this.filters[0].options = data || [];
    },
    async getAgentsInARelationship() {
      const data = await ClientsConnectionService.getAgentsInRelationship();
      data.map((item) => {
        item.radio = false;
      });
      this.advisorsList = data || [];
    },
    async openModalProgram(data) {
      if (!data) return;
      const param = {
        prices: null,
        observation: "Service",
        contract: 1,
        program: data.program.id,
        fee: null,
        suggested: null,
        otherpricesp: null,
        event: this.event,
        json_noce: null,
        stateid: 0,

        // Diferents to add change Services
        account: this.client_id.account_id,
        captured: data.captured.id,
        seller: data.seller.id,
        type: this.typeModal === 3 ? 1 : 2,
        user_id: this.currentUser.user_id,
        module: 20,
        id_score: null,
        json_ce: null,
        type_client: "CLIENT",
      };
      try {
        let result = await LeadService.insertSaleMade(param);
        if (result.status == 200) {
          this.modalOpenAddService = false;
          this.$router
            .push({ name: "sales-made-connection-client" })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        this.showErrorSwal(
          "Please, verify if the client already has the service, otherwise contact support.",
          "Error"
        );
      } finally {
        this.removePreloader();
      }
    },
    closeSendToRecycleModalAfterAction() {
      this.isChangeClientStatusModalOpened = false;
      this.$refs.refClientsCeTable.refresh();
    },
    closeSendToRecycle() {
      this.isChangeClientStatusModalOpened = false;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    closeSetServicesModal() {
      this.isSetServicesModalOpened = false;
    },
    closeSetServicesModalAfterAction() {
      this.$router.push({ path: "/cedigital/connection/sales-made" });
      this.isSetServicesModalOpened = false;
      this.$refs.refClientsCeTable.refresh();
    },
    async openMonthlyPaymentModal(clientInfo) {
      await this.addPreloader();
      this.currentClient = clientInfo;
      await this.SET_CURRENT_CLIENT({ id: clientInfo.account_id });
      this.isMonthlyPaymentModalOpened = true;
      await this.removePreloader();
    },
    closeMonthlyPaymentModal() {
      this.isMonthlyPaymentModalOpened = false;
    },
    openModalSendSms() {
      var namecl = [];
      this.clientsSelected.map((dato) => {
        namecl.push({
          name: dato.lead_name,
          id: dato.account_id,
          account: dato.account_id,
        });
      });
      this.name_clients_arr = namecl;
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
      this.selectAll = false;
      this.clientsToAssignAdvisor = [];
      this.clientsSelected = [];
      this.modalAssignAdvisorState = false;
    },
    addCountSms(data) {
      this.counter_sms = data;
    },
    updateTable() {
      this.filterPrincipal.model = "";
      this.$refs.refClientsCeTable.refresh();
    },
    async connectionProcessWhenHoldPayMonthlyPayment({ idcard, amount }) {
      try {
        await this.sendClientToConnection();

        if (
          this.ncrAndMonthlyPaymentComissionUsers &&
          this.currentModule == 20
        ) {
          await this.generateCommission(
            this.currentClient.account_id,
            amount,
            "PERCENTAGE",
            "con-hold"
          ); // Reconnection
          this.updateTable();
        }
        this.showSuccessSwal();
        this.refreshTableClients();
      } catch (err) {
        console.error(err);
      } finally {
        this.removePreloader();
      }
    },
    openModalAsign() {
      this.typeOfAssignment = 1;
      let clientsData = this.clientsSelected.map(
        ({ account_id, lead_name, account, client_status_connection }) => ({
          client_account_id: account_id,
          name: lead_name,
          account: account,
          status_connection: client_status_connection,
        })
      );
      this.clientsToAssignAdvisor = clientsData;
      this.clientsToAssignAdvisorSelected = clientsData;
      this.modalAssignAdvisorState = true;
    },
    openModalAsignAgentIndividual({
      account_id,
      lead_name,
      account,
      advisor_connection_id,
      client_status_connection,
    }) {
      if (!!advisor_connection_id) {
        this.typeOfAssignment = 2;
      } else {
        this.typeOfAssignment = 1;
      }
      this.openModalIndividualState = true;
      this.clientsToAssignAdvisor = [
        {
          client_account_id: account_id,
          name: lead_name,
          account: account,
          status_connection: client_status_connection,
        },
      ];
      this.clientsToAssignAdvisorSelected = this.clientsToAssignAdvisor;
      this.modalAssignAdvisorState = true;
    },
    closeModalAssignAdvisor() {
      if (this.openModalIndividualState) {
        this.openModalIndividualState = false;
      }
      this.selectAll = false;
      this.clientsToAssignAdvisor = [];
      this.clientsSelected = [];
      this.modalAssignAdvisorState = false;
    },
    selectedAll() {
      if (this.selectAll) {
        var namecl = [];
        this.items.map((dato) => {
          namecl.push(dato);
        });
        this.clientsSelected = namecl;

        var accountSelected = [];
        this.items.map((dato) => {
          accountSelected.push(dato.account);
        });
        this.account_client = accountSelected;
      } else {
        this.clientsSelected = [];
      }
    },
    async refreshTableClients() {
      this.$refs.refClientsCeTable.refresh();
    },
    async generateCommission(clientAccountId, amount, type, slug) {
      try {
        await ClientsOptionsServices.generateComission({
          client_account_id: clientAccountId,
          amount: amount,
          type_commission: type,
          slug: slug,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async sendClientToConnection() {
      try {
        await DashboardService.sendClientToConnection({
          client_id: this.currentClient.client_id,
          client_account_id: this.currentClient.account_id,
          in_connection: "CONNECTION",
          status_connection: "RECOVERED",
          comment: "",
          user_id: this.currentUserId,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async sendPusherByNcr(clientAccounts) {
      try {
        await ClientsConnectionService.sendPusherByNcr({
          client_names: clientAccounts,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async closeNewRelationshipModal(success = false) {
      this.activeNewRelationshipModal = false;
      if (success) await this.openMonthlyPaymentModal(this.currentClient);
      this.currentClient = null;
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      Vue.set(this.detailsClient, "event_id", this.event);
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    async openAddService(typeModal, client) {
      this.client_id = client;
      this.typeModal = typeModal;
      const result = await this.showConfirmSwal(
        typeModal === 3
          ? "Are sure want to add new service?"
          : "Are sure want to change service?"
      );
      if (result.value) {
        await this.createEvent(client);
        let data = {
          sales: client.sales,
          lead_name: client.lead_name,
          lead_id: client.lead_id,
          accounts: [
            {
              program: "Credit Experts",
            },
          ],
        };
        this.modalGeneralService(data, typeModal);
      }
    },
    async createEvent(client) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: client.lead_id,
      });
      this.event = response.id;
    },
    openDerivationActionModal(item, index) {
      this.derivationData = item;
      this.derivationIndex = index;
      this.derivationActionModal = true;
    },
    closeDerivationActionModal() {
      this.derivationActionModal = false;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    updateRow() {
      this.$set(this.items[this.derivationIndex], "is_derivated", 1);
    },
    async getStatesEeuu() {
      try {
        let dataState = await GlobalService.getStatesEEuu();
        this.filters[6].options = dataState.data;
        this.filter[6].options.unshift({
          state: "All",
          slug: 0,
        });
      } catch (e) {}
    },
    refreshTableClientsAssign(name_agent) {
      this.clientsToAssignAdvisorSelected.map((item) => {
        this.items.map((item2, index) => {
          if (item.client_account_id == item2.account_id) {
            this.$set(this.items[index], "advisor_connection_name", name_agent);
          }
        });
      });
    },
    async getResults() {
      const response = await DebtorClientsService.getResultsClient();
      this.results = response;
    },
    async getLabels() {
      const response = await DebtorClientsService.getLabelsClient();
      this.labels = response;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      G_CE_GRID_COUNTERS: "connectionStore/G_CE_GRID_COUNTERS",
    }),
    currentTab() {
      const inLoyalOrHoldTab = !["RECYCLE", "CLOSED"].includes(
        this.$route.meta.connectionStatus
      );

      this.stateAssign =
        inLoyalOrHoldTab && [1, 2, 14, 17].includes(this.currentRoleId);

      return this.$route.meta.connectionStatus;
    },
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    currentRoleId() {
      return this.currentUser.role_id;
    },
    currentUserId() {
      return this.currentUser.user_id;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isCeo() {
      return this.currentRoleId === 1;
    },
    isSupervisorOrChief() {
      return this.currentRoleId === 2 || this.currentRoleId === 17;
    },
    isTeamLeader() {
      return this.currentRoleId === 11;
    },
    isAssistant() {
      return this.currentRoleId === 14;
    },
    isAgent() {
      return this.currentRoleId === 15;
    },
    hasAllAccess() {
      return this.isCeo || this.isSupervisorOrChief || this.isTeamLeader;
    },
    allAccessGrid() {
      return this.hasAllAccess || this.isAssistantSupervisor;
    },
    ncrAndMonthlyPaymentComissionUsers() {
      return !this.isCeo && !this.isSupervisorOrChief;
    },
    existClientsWithOutAdvisorAssigned() {
      return this.clientsSelected.length > 0;
    },
    existClientsForSendMessages() {
      return this.clientsSelected.length > 0;
    },
    isRecyclePendingOrDenied() {
      return this.$route.meta.recycleStatusId == 1 ||
        this.$route.meta.recycleStatusId == 3
        ? true
        : false;
    },
    isClosedPendingOrDenied() {
      return this.$route.meta.closedStatusId == 4 ||
        this.$route.meta.closedStatusId == 6
        ? true
        : false;
    },
    connectionState() {
      if (this.isRecyclePendingOrDenied || this.isClosedPendingOrDenied)
        return "";
      return this.currentTab;
    },
    clientAccountId() {
      return this.$route.params.idClient;
    },
    currentTabStatusId() {
      return this.$route.meta.tabs[this.$route.meta.tab].subTabs.find(
        (item) => item.name === this.$route.name
      ).status_id;
    },
    tabTitle() {
      return this.$route.meta.tabs[this.$route.meta.tab].text;
    },
    isOutstanding() {
      return this.tabTitle === "Outstanding";
    },
  },
};
</script>
<style scoped lang="scss">
/*
.floating-badge {
  position: absolute;
  top: 0px;
  right: 0px;
}
.badge-font-size {
  font-size: 1rem !important;
  padding: 0.3rem !important;
}*/
</style>

<style lang="scss">
.mp-pending {
  animation: growup 1.5s ease-in-out infinite;
  top: calc(50% - 10px);
}
@keyframes growup {
  0% {
    transform: scale(1);
    color: #ff9f43;
  }
  50% {
    transform: scale(0.99);
    color: #fc3a31;
  }
  100% {
    transform: scale(1);
    color: #ff9f43;
  }
}
</style>
