export default [
  {
      key: "radio",
      label: "Select",
      class: "text-left",
      visible: true,
      thStyle: { width: "10%" },
  },    
  {
      key: "agent_name",
      label: "Agent",
      class: "text-center padding-y",
      visible: true,
      thStyle: { width: "30%" },
  },
  {
      key: "loyal",
      label: "Loyal",
      class: "text-center padding-y",
      visible: true,
      thStyle: { width: "30%" },
  },
  {
      key: "hold",
      label: "Hold",
      class: "text-center padding-y",
      visible: true,
      thStyle: { width: "30%" },
  },
]