export default [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Agent",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user",
    cols: 12,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From (Enroll Date)",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To (Enroll Date)",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From (Connection Date)",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To (Connection Date)",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Client Type",
    model: null,
    options: [
      { id: 1, text: "Regular" },
      { id: 2, text: "Digital" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "State of US",
    model: null,
    options: [],
    reduce: "slug",
    selectText: "state",
    cols: 6,
    visible: true,
  },
  {
    type: "input",
    margin: true,
    showLabel: true,
    label: "Min Efectivity",
    model: null,
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "input",
    margin: true,
    showLabel: true,
    label: "Max Efectivity",
    model: null,
    selectText: "text",
    cols: 6,
    visible: true,
  },
  // {
  //   type: "select",
  //   margin: true,
  //   showLabel: true,
  //   label: "Client status",
  //   model: null,
  //   options: [
  //     { id: null, text: "All" },
  //     { id: 2, text: "Hold" },
  //     { id: 5, text: "Loyal" },
  //   ],
  //   reduce: "id",
  //   selectText: "text",
  //   cols: 6,
  //   visible: true,
  // },
];
