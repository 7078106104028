<template>
  <div>
    <b-modal
      modal
      v-model="modalService"
      ref="modalService"
      size="lg"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title-class="h2 text-white"
      hide-footer
      :no-close-on-backdrop="true"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <modal-service-header
          :type-modal="typeModal"
          :programs-all="programsAll"
          :users-services="usersServices"
          :sales="Client"
          :twoPerRow="false"
          @close="hideModal(false)"
          @changeProgram="changeProgram"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import ModalServiceHeader from "@/views/crm/views/sales-made/components/modals/services/ModalServiceHeader.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
// Import Services
import ClientService from "@/views/commons/components/digital/services/clients.service.js";
export default {
  components: {
    ButtonSave,
    ButtonCancel,
    ModalServiceHeader,
  },
  props: {
    modalOpenAddService: {
      type: Boolean,
    },
    detailsClient: {
      type: Object,
    },
    typeModal: {
      type: [Number, String],
    },
  },
  data() {
    return {
      modalService: this.modalOpenAddService,
      Client: {},
      programsAll: [],
      usersServices: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentUserId() {
      return this.currentUser.user_id
    },
    currentModule() {
      return this.$route.matched[0].meta.module
    },
    currentModalTitle() {
      return this.serviceActionType == 3 ? 'Add Service' : 'Change Service'
    },
    currentClientWithSeller() {
      return { ...this.currentClient, seller: { id: this.sellerSelected }, captured: { id: this.catcherSelected } }
    },
    showBusinessServiceModal() {
      return this.programSelected == 1 && !!this.catcherSelected && !!this.sellerSelected
    },
    showTaxResearchServiceModal() {
      return this.programSelected == 5 && !!this.catcherSelected && !!this.sellerSelected
    },
    showBoostCreditServiceModal() {
      return this.programSelected == 2 && !!this.catcherSelected && !!this.sellerSelected
    },
    showDebtSolutionServiceModal() {
      return this.programSelected == 4 && !!this.catcherSelected && !!this.sellerSelected
    },
    showOtherModulesServiceModal() {
      return [6, 7, 8].includes(this.programSelected) && !!this.catcherSelected && !!this.sellerSelected
    },
    showDefaultCreditExpertsServiceModal() {
      return !( this.showBusinessServiceModal || this.showTaxResearchServiceModal || this.showBoostCreditServiceModal || this.showOtherModulesServiceModal || this.showDebtSolutionServiceModal)
    }
  },
  methods: {
    ...mapActions("crm-store", ["SET_PROGRAM_HEADER"]),
    closeModal() {
      this.$emit("closeModalService");
    },
    changeProgram(data) {
      this.$emit("changeProgram", data);
    },
    hideModal() {
      this.$refs['modalService'].hide();
      this.$emit("hideModal", false);
    },
    usersAllPrograms: async function () {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.$route.meta.moduleParagon
            ? 12
            : this.$route.matched[0].meta.module === 12 || this.$route.matched[0].meta.module === 3
            ? 3
            : this.$route.matched[0].meta.module,
        });
        this.usersServices = data.data;
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    searchPrograms: async function (lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          let programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort(function (a, b) {
            return a.program_id - b.program_id;
          });
          for (var i = 0; i < this.programsAll.length; i++) {
            for (var y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.changeProgram();
    this.SET_PROGRAM_HEADER(this.detailsClient.accounts[0].program);
  },
  created() {
    this.Client = {
      lead_name: this.detailsClient.lead_name,
      program: this.detailsClient.accounts[0].program,
      ...this.detailsClient,
    };
    this.SET_PROGRAM_HEADER(null);
    this.searchPrograms(this.detailsClient.lead_id);
    this.usersAllPrograms();
  },
};
</script>
