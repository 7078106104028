<template>
  <b-nav card-header pills class="m-1 ml-1">
    <b-nav-item
      v-for="(subTab, index) in subTabs"
      :key="index"
      :to="{ name: subTab.name }"
      exact
      exact-active-class="active"
      :link-classes="['px-3', bgTabsNavs]"
      v-b-tooltip.hover
      :title="G_CE_GRID_COUNTERS[0][replaceSpaceWithUnderscore(subTab.text)]"
    >
      {{ subTab.text }}

      <span
        class="ml-3"
        v-if="
          G_CE_GRID_COUNTERS.length > 0 &&
          G_CE_GRID_COUNTERS[0][replaceSpaceWithUnderscore(subTab.text)] > 0
        "
      >
        <feather-icon
          icon
          :badge="
            G_CE_GRID_COUNTERS[0][replaceSpaceWithUnderscore(subTab.text)]
          "
          badge-classes="badge-danger"
        />
      </span>
    </b-nav-item>
  </b-nav>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ClientsTabsOutstanding",
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      G_CE_GRID_COUNTERS: "connectionStore/G_CE_GRID_COUNTERS",
    }),
    subTabs() {
      return this.$route.meta.tabs[this.$route.meta.tab].subTabs;
    },
  },
  methods: {
    replaceSpaceWithUnderscore(text) {
      return text.toLowerCase().replace(/\s+/g, "_");
    },
  },
};
</script>