<template>
  <div>
    <b-modal
      v-model="control"
      title-class="h3 text-white"
      centered
      header-class="text-center"
      size="lg"
      title="List of observations"
      modal-class="modal-primary"
      @hidden="close"
    >
      <b-row>
        <b-col class="text-right">
          <b-button
            v-if="!stateForm"
            class="mr-1 mb-1"
            variant="primary"
            @click="statusForm()"
            >Create</b-button
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" v-if="stateForm" class="pt-2">
          <!--::::::::INI FORM:::::::::-->
          <ValidationObserver ref="form">
            <b-row>
              <b-col cols="12">
                <!-- v-slot="{ errors }" -->
                <validation-provider name="date" rules="required">
                  <b-form-group label="Title" label-cols-md="2">
                    <b-form-input v-model="title" />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-col>

        <b-col cols="12" v-if="!stateForm">
          <b-table
            small
            slot="table"
            :items="getReasonListConnectionAll"
            :fields="fields"
            responsive="sm"
            :busy.sync="isBusy"
            sticky-header="35vh"
            ref="table_list_observation"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" variant="primary" />
                <strong class="ml-1 text-primary">Loading...</strong>
              </div>
            </template>

            <template #cell(created_at)="data">
              {{ data.item.created_at | myGlobal }}
            </template>

            <template #cell(actions)="data">
              <feather-icon
                v-b-tooltip.hover.top="'Delete'"
                class="cursor-pointer ml-2"
                icon="TrashIcon"
                size="15"
                style="color: #f00"
                @click="deleteObservation(data.item.id)"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div>
          <b-button
            v-if="stateForm"
            class="mr-1"
            variant="success"
            @click="stateForm = !stateForm"
            >CANCEL</b-button
          >

          <b-button
            v-if="stateForm"
            class="mr-1"
            variant="success"
            @click="saveForm"
            >SAVE</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import fieldsReasonListData from "@/views/ce-digital/sub-modules/connection/views/clients/data/fields.reason.list.data.js";
import ClientsConnectionService from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service.js";

export default {
  props: {
    typeStatus: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      control: false,
      items: [],
      fields: fieldsReasonListData,
      isBusy: false,
      stateForm: false,
      title: "",
    };
  },
  async created() {
    this.control = true;
  },
  methods: {
    close() {
      this.$emit("closeModal");
      this.control = false;
    },
    async getReasonListConnectionAll() {
      const param = {
        status: this.typeStatus == 1 ? "RECYCLE" : "CANCELED",
      };
      const { data } = await ClientsConnectionService.getReasonStateChangeAll(
        param
      );
      data ? (this.items = data.data) : (this.items = []);
      return this.items;
    },
    async deleteObservation(idObservation) {
      const response = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!"
      );
      if (response.isConfirmed) {
        this.addPreloader();
        const param = {
          id_observation: idObservation,
        };
        const { data } = await ClientsConnectionService.deleteObservation(
          param
        );
        data ? this.$refs.table_list_observation.refresh() : "";
        this.removePreloader();
      }
    },
    statusForm() {
      this.stateForm = !this.stateForm;
    },
    async saveForm() {
      this.addPreloader();
      const param = {
        name: this.title,
        status: this.typeStatus == 1 ? "RECYCLE" : "CANCELED",
      };
      const { data } = await ClientsConnectionService.insertReasonStateChange(
        param
      );
      data ? (this.title = "") : "";

      this.removePreloader();
      this.stateForm = !this.stateForm;
      this.$parent.getReasonRecycle();
    },
  },
};
</script>
