<template>
  <div>
    <b-modal
			v-model="control"
      title-class="h3 text-white"
      centered
      size="md"
      :title="`Send to ${toUpperCaseNewConnectionStatus}`"
      modal-class="modal-primary"
      @hidden="close"
    >
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary">CLIENT</b-input-group-text>
              </template>
              <b-form-input
              style="border-color:rgba(255, 255, 255, 0.4)"
              :style="isDarkSkin ? 'background: #17171A;opacity: 0.7;color:white': 'background: #efefef'"
              v-model="currentClient.lead_name"
              disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary">ACCOUNT</b-input-group-text>
              </template>
              <b-form-input
                style="border-color:rgba(255, 255, 255, 0.4)"
                :style="isDarkSkin ? 'background: #17171A;opacity: 0.7;color:white': 'background: #efefef'"
                v-model="currentClient.account"
                disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
          >
            <ValidationProvider v-slot="{errors}" name="Observation" rules="required">
              <b-form-group label="Observation" label-for="Observation">
                <b-input-group>
                    <b-form-select
                    id="Observation"
                    no-resize
                    :options="reasons"
                    :state="errors[0] ? false : null"
                    v-model="reasonId"
                    value-field="id"
                    text-field="name"
                    />
                    
                    <b-input-group-prepend variant="light">
                      <b-button
                      variant="outline-info"
                      class="text-center"
                      style="height:36px"
                      @click="openModalChangeStateForReason"
                      >
                        <feather-icon
                          icon="AlignJustifyIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                    </b-input-group-prepend>
                    
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div>
          <b-button class="mr-1" variant="success" @click="sendToRecycleOrCanceled()">SEND</b-button>
        </div>
      </template>
    </b-modal>
    
    <reason-list-modal
    v-if="reasonListModalState"
    @closeModal="closeModalReasonList"
    :typeStatus="typeStatusRequest"
    />
    
  </div>
</template>
<script>
// Modal
import ReasonListModal from "@/views/ce-digital/sub-modules/connection/views/clients/components/modals/ReasonListModal.vue";

import ClientsConnectionService from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service"
import { mapGetters, mapMutations, mapState } from "vuex"
  export default {
    components: {
        ReasonListModal
    },
    props: {
      currentClient: {
        type: Object,
        required: true,
      },
      typeStatusRequest: {
        type: Number,
        default: 1,
      }
    },
    data() {
      return {
        control: false,
        reasonId: null,
        reasons: [],
        reasonListModalState:false,
      }
    },
    async created() {
      this.control = true
      this.getReasonRecycle();
    },
    computed: {
      ...mapGetters({
        currentUser: "auth/currentUser",
      }),
      ...mapState("connectionStore", ["S_COUNTER_RECYCLE"]),
      currentUserId() {
        return this.currentUser.user_id
      },
      currentTab() {
        return this.$route.meta.connectionStatus
      },
      newConnectionStatus() {
        if(this.sendToRecycle) return 'RECYCLE';
        if(this.sendToClosed) return 'CANCELED';
      },
      toUpperCaseNewConnectionStatus() {
        return this.newConnectionStatus[0] + this.newConnectionStatus.toLowerCase().substring(1)
      },
      sendToRecycle() {
        return this.typeStatusRequest === 1
      },
      sendToClosed() {
        return this.typeStatusRequest === 2
      },
      setPendingInClosedOrRegular() {
        if(this.sendToRecycle) { return 1 }
        if(this.sendToClosed) { return 5 }
      },
      sendToCanceled(){
        return this.$route.meta.connectionStatus == 'RECYCLE' && this.setPendingInClosedOrRegular == 5 ? true : false;
      }
    },
    methods: {
        ...mapMutations({
        countRecyclePendingMutation: "connectionStore/M_COUNTER_RECYCLE",
        
      }),
      async sendToRecycleOrCanceled() {
        let observation = this.reasons.filter((field) => field.id == this.reasonId );
        observation ? observation = observation[0].name : '' ;
        
        const validation = await this.$refs.form.validate()
        if (!validation) return
        const swalResponse = await this.showConfirmSwal()
        if(!swalResponse.isConfirmed) return
        this.addPreloader()
        try {
          const params = {
            client_id: this.currentClient.client_id,
            client_account_id: this.currentClient.account_id,
            in_connection: "CONNECTION",
            status_connection: this.sendToCanceled ? 'CANCELED' : this.$route.meta.connectionStatus,
            reason_connection: "",
            advisor_id: "",
            comment: observation, //this.reasons[this.reasonId].text,
            user_id: this.currentUserId,
            client_status_request: this.setPendingInClosedOrRegular,
          }
          const { status } = await ClientsConnectionService.sendClientToRecycleOrClosed(params)
          if(status === 200) this.showSuccessSwal()
          this.$emit('refresh')
          this.removePreloader()
          this.closeAndUpdate()
         
        } catch (err) {
          this.removePreloader()
          console.error(err)
        }
      },
      close() {
        this.$emit('close');
        this.control = false;
      },
      closeAndUpdate() {
        this.$emit('closeAndUpdate')
        this.control = false
      },
      openModalChangeStateForReason(){
          this.reasonListModalState = true;
      },
      closeModalReasonList(){
          this.reasonListModalState = false;
      },
      async getReasonRecycle(){
        this.addPreloader();
        const params = {
            status: this.sendToRecycle ? 'RECYCLE' : 'CANCELED',
        }
        const { data } = await ClientsConnectionService.getReasonStateChange(params)
        if(data){
          this.reasons = data.data
        }
        this.removePreloader();
      }
    }
  }
</script>